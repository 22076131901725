import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/seo"

const LinksPage = () => {
  return (
    <Layout>
      <SEO title="Links" />
      <div
        className="app"
        style={{ height: "100vh", backgroundColor: "#F4F4FC" }}
      >
        <section style={{ paddingTop: "160px" }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-7">
                <div className="card panel-card">
                  <div className="card-body table-responsive">
                    <table className="table">
                      <thead>
                        <th>Platform</th>
                        <th>Test</th>
                        <th>Live</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Deprecated</td>
                          <td>
                            <a
                              href="https://web.clairbleu.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              web.clairbleu.com
                            </a>
                          </td>
                          <td>
                            <a
                              href="https://shelfie-temp.netlify.app"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              shelfie-temp.netlify.app
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Pilot (Web)</td>
                          <td>
                            -
                          </td>
                          <td>
                            <a
                              href="https://weshelfie-old.netlify.app"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              weshelfie-old.netlify.app
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>ClearBlue</td>
                          <td>
                            <a
                              href="https://weshelfie.netlify.app"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              weshelfie.netlify.app
                            </a>
                          </td>
                          <td>
                            <a
                              href="https://clearblue.weshelfie.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              clearblue.weshelfie.com
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default LinksPage
